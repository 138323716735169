import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import FreeTrialLesson from "../components/FreeTrialLesson"
import CustomersSay from "../components/CustomersSay"
import { Link } from "gatsby"
import Faq from "../components/Faq"
import SEO from "../components/SEO/seo"
import { siteData } from "../data/siteData"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"
import beliefs1Img from "../images/beliefs-1.svg"
import beliefs4Img from "../images/beliefs-4.svg"
import keyPointImgSchoolEvents from "../images/home-keypoint-school-events.svg"
import keyPointImgFriendly from "../images/home-keypoint-friendly.svg"

/*
  {
    image: "homeBannerImage6",
    title: "",
    des: ""
  },
  {
    image: "homeBannerImage5",
    title: "",
    des: ""
  },
  ,

 */

const sliderdata = [
  {
    image: "homeBannerImage1",
    title: "習い、吸収し、成長する。",
    des: "英語を知りたい好奇心を大きく育ててしっかりスキルを身につけよう。",
  },
  {
    image: "homeBannerImage3",
    title: "間違えることを恐れない。",
    des: "失敗を恐れずに積極的にコミュニケーションを取ろう。",
  },
  {
    image: "homeBannerImage2",
    title: "世界へ目を向ける。",
    des: "先生を通して人種や文化の違いに気付いて新たな発見をしよう。",
  }

];

const HomePage = () => {
  const bannerSectionSliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query GrowSchoolFacilityImagesQuery {
      homeBannerImage1: file(relativePath: { eq: "home-banner-image-1.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 1440, 
            quality: 100,
            srcSetBreakpoints: [485, 815, 975, 1440]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homeBannerImage2: file(
        relativePath: { eq: "home-banner-image-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, 
            quality: 100,
            srcSetBreakpoints: [485, 815, 975, 1440]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homeBannerImage3: file(relativePath: { eq: "home-banner-image-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, 
            quality: 100,
            srcSetBreakpoints: [485, 815, 975, 1440]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homeBannerImage4: file(relativePath: { eq: "AEH-2021-1-Banner2-website-1440x650px.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, 
            quality: 100,
            srcSetBreakpoints: [485, 815, 975, 1440]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homeBannerImage5: file(relativePath: { eq: "AEH-2021-1-Banner1-website-1440x650px.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, 
            quality: 100,
            srcSetBreakpoints: [485, 815, 975, 1440]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homeBannerImage6: file(relativePath: { eq: "AEH-2021-8-Banner1-website-1440x650px.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, 
            quality: 100,
            srcSetBreakpoints: [485, 815, 975, 1440]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
     
      
      classKindyImage: file(relativePath: { eq: "home-class-kindy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        } 
      }
      classElementaryImage: file(relativePath: { eq: "home-class-elementary.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      classJhsHsImage: file(relativePath: { eq: "home-class-jhs-hs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      classAdultImage: file(relativePath: { eq: "classes-adult.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      inquiryAvatarImage: file(relativePath: { eq: "lp-contact-avtar.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    
    }
  `)

  return (
    <>
      <Layout>
        <SEO metaData={siteData.homePage} siteData={siteData}/>
        {/* <!-- Banner section --> */}

        <section className="banner-section">
          <div className="banner-wrapper"> 
            <Slider {...bannerSectionSliderSettings}>
              {sliderdata.map((fac, i) => {

                return (
                  <div className="banner-item bg-darkblue" key={i}>
                    <Img
                      fluid={data[fac.image].childImageSharp.fluid}
                      className="banner-img"
                    />
                    <div className="banner-title-container">
                      {fac.title !== '' &&(
                        <div className="banner-title-wrapper">
                          <h1 className="banner-title">{fac.title}</h1>
                          <div className="banner-subtitle">{fac.des}</div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>

        </section>

        {/* <!-- Welcome text section --> */}

        <section className="welcome-section inner-padding">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 text-center">
                <h2 className="title">
                  アメリカンイングリッシュハウスへようこそ
                </h2>
                <div className="static-text">
                  <p>
                    アメリカンイングリッシュハウスは海部郡大治町にある、こどもをメインとした英会話スクールです。２００１年の来日以降、英語教育に携わってきた経験豊富なアメリカ人講師がレッスンを行っています。アットホームな少人数制スクールで、楽しくわかりやすいレッスンを心がけ一人一人しっかりと指導しています。園児クラス、小学生クラス、中高生クラス、大人クラスがあり、大治町だけでなくあま市甚目寺町や名古屋市中川区からも生徒が多く通っています。
                  </p>

                  <div className="button-wrap mt-30">
                    <Link to="/about-us" className="btn btn-secondary btn-icon icon-right">
                      <div className="btn-inner">
                        <div className="btn-text">詳しくはこちら</div>
                        <i className="btn-icon-right icon icon-arrow-right" aria-hidden="true" />
                      </div>

                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>




        {/* <!-- Welcome text section --> */}
        <section className="class-grid-section inner-padding pb-0">
          <div className="container">
            <div className="row">
              <ScrollAnimation
                //offset={80}
                animateIn="fadeInUp"
                duration={1}
                animateOnce={true}
                className="col-sm-6 col-xs-12 class-grid-cols text-center"
              >
                <Link
                  to="/classes#childrens-class"
                  className="class-grid-image-wrapper"
                >
                  <div className="class-grid-image">
                    <Img
                      fluid={data['classKindyImage'].childImageSharp.fluid}
                      className="class-img" alt="園児クラス"
                    />
                  </div>
                  <div className="class-grid-content bg-yellow">
                    <strong>園児クラス</strong>
                    <i className="icon icon-arrow-right" aria-hidden="true" />
                  </div>
                </Link>
              </ScrollAnimation>

              <ScrollAnimation
                //offset={80}
                delay={500}
                animateIn="fadeInUp"
                duration={1}
                animateOnce={true}
                className="col-sm-6 col-xs-12 class-grid-cols text-center"
              >
                <Link
                  to="/classes#elementry-class"
                  className="class-grid-image-wrapper"
                >
                  <div className="class-grid-image">
                    <Img
                      fluid={data['classElementaryImage'].childImageSharp.fluid}
                      className="class-img" alt="小学生クラス"
                    />

                  </div>
                  <div className="class-grid-content bg-green">
                    <strong>小学生クラス</strong>{" "}
                    <i className="icon icon-arrow-right" aria-hidden="true" />
                  </div>
                </Link>
              </ScrollAnimation>
              <ScrollAnimation
                //offset={80}
                animateIn="fadeInUp"
                duration={1}
                animateOnce={true}
                className="col-sm-6 col-xs-12 class-grid-cols text-center"
              >
                <Link
                  to="/classes#junior-class"
                  className="class-grid-image-wrapper"
                >
                  <div className="class-grid-image">
                    <Img
                      fluid={data['classJhsHsImage'].childImageSharp.fluid}
                      className="class-img" alt="中高生クラス"
                    />
                  </div>
                  <div className="class-grid-content bg-blue">
                    <strong>中高生クラス</strong>{" "}
                    <i className="icon icon-arrow-right" aria-hidden="true" />
                  </div>
                </Link>
              </ScrollAnimation>
              <ScrollAnimation
                //offset={80}
                delay={500}
                animateIn="fadeInUp"
                duration={1}
                animateOnce={true}
                className="col-sm-6 col-xs-12 class-grid-cols text-center"
              >
                <Link
                  to="/classes#adult-class"
                  className="class-grid-image-wrapper"
                >
                  <div className="class-grid-image">
                    <Img
                      fluid={data['classAdultImage'].childImageSharp.fluid}
                      className="class-img" alt="大人クラス"
                    />
                  </div>
                  <div className="class-grid-content bg-red">
                    <strong>大人クラス</strong>{" "}
                    <i className="icon icon-arrow-right" aria-hidden="true" />
                  </div>
                </Link>
              </ScrollAnimation>
            </div>
          </div>
        </section>

        {/* <!-- Free Trial Lesson --> */}
        <FreeTrialLesson title="無料体験レッスン" button="お申し込みはこちら" siteData={siteData.site}/>


        <ScrollAnimation
          //offset={80}
          animateIn="fadeInUp"
          duration={1}
          animateOnce={true}
          //className=""
        >
          <section className="key-points-section section-wide bg-gray text-center">
            <div className="inner-padding">
              <div className="container">
                <h3 className="title">AEHのポイント！</h3>

                <div className="key-points-flex">
                  <div className="key-points-item">
                    <img
                      src={keyPointImgFriendly}
                      alt="気さくな先生"
                      className="we-img sm-img"
                    />
                    <h4 className="key-points-item-title">気さくな先生</h4>
                  </div>

                  <div className="key-points-item">
                    <img
                      src={beliefs1Img}
                      alt="少人数制"
                      className="we-img sm-img"
                    />
                    <h4 className="key-points-item-title">少人数制</h4>
                  </div>


                  <div className="key-points-item">
                    <img
                      src={beliefs4Img}
                      alt="初心者歓迎"
                      className="we-img sm-img"
                    />
                    <h4 className="key-points-item-title">初心者歓迎</h4>
                  </div>

                  <div className="key-points-item">
                    <img
                      src={keyPointImgSchoolEvents}
                      alt="スクールのイベント"
                      className="we-img sm-img"
                    />
                    <h4 className="key-points-item-title">スクールのイベント</h4>
                  </div>

                </div>

                <div className="button-wrap mt-10">
                  <Link to="/about-us" className="btn btn-secondary btn-icon icon-right">
                    <div className="btn-inner">
                      <div className="btn-text">詳しくはこちら</div>
                      <i className="btn-icon-right icon icon-arrow-right" aria-hidden="true" />
                    </div>

                  </Link>
                </div>

              </div>


            </div>



          </section>
        </ScrollAnimation>






        {/* <!-- What Our Customers Say --> */}
        <CustomersSay title="お母さんたちからのコメント" />

        <ScrollAnimation
          //offset={80}
          animateIn="fadeInUp"
          duration={1}
          animateOnce={true}
          //className=""
        >
          <Faq title="よくあるご質問" siteData={siteData.site}/>
        </ScrollAnimation>



        {/* <!-- For Inquiry Call Us On --> */}
        <section className="inquiry-section inner-padding extra-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="inquiry-wrapper flex">
                  <div className="inquiry-cols flex">
                    <h3 className="title textLeft mb-15">
                      電話でのお問い合わせ
                    </h3>
                    <h3>
                      <ScrollAnimation
                        // offset={80}
                        animateIn="bounce"
                        duration={1}
                        animateOnce={true}
                      >
                        <Link href={`tel:${siteData.site.contactPhone}`} className="link-blue">
                          <i className="icon icon-x2 icon-call-blue" />
                          {siteData.site.contactPhone}
                        </Link>
                      </ScrollAnimation>
                    </h3>
                  </div>
                  <div className="inquiry-cols button-wrap">
                    <Link to="/contact-us" className="btn btn-secondary">
                      お問い合わせはこちら
                    </Link>
                  </div>
                  <div className="inquiry-avatar">
                    <Img
                      fluid={data['inquiryAvatarImage'].childImageSharp.fluid}
                      className="inquiry-avatar-img"
                      alt="お問い合わせはこちら"
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>

        </section>
      </Layout>
    </>
  )
}
export default HomePage
