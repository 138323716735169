import React, { useState } from "react"
import { Link } from "gatsby"

const Faq = props => {
  const { title, siteData } = props
  const [activeFaq, setActiveFaq] = useState("")

  function handleShowFaq(e, value) {
    if (activeFaq !== value) {
      setActiveFaq(value)
    } else {
      setActiveFaq("")
    }
  }

  return (
    <>
      {/* <!-- Frequently Asked Questions --> */}
      <section className="faqs-section section-wide bg-gray">

        <div className="inner-padding">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="title">{title}</h3>
                <div className="faqs-wrapper">
                  <div
                    className={
                      activeFaq === +1 ? "faqs-item active" : "faqs-item"
                    }
                    onClick={e => handleShowFaq(e, +1)}
                  >
                    <div className="faq-link" >
                      <span className="faq-icon" />
                      「体験レッスンを受けたいのですが…」
                    </div>
                    <div className="faq-answer">
                      <p>
                        「体験レッスンは無料でいつでも受けていただけます。お電話またはe-mailでお申込みください。
                        Tel: <Link href={`tel:${siteData.contactPhone}`}>{siteData.contactPhone}</Link>
                        e-mail:{" "}
                        <Link href={`mailto:${siteData.contactEmail}`}>
                          {siteData.contactEmail}
                        </Link>{" "}
                        」
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      activeFaq === +2 ? "faqs-item active" : "faqs-item"
                    }
                    onClick={e => handleShowFaq(e, +2)}
                  >
                    <div className="faq-link" >
                      <span className="faq-icon" />
                      「いつ入会できますか？入会金など最初にかかる費用は？」
                    </div>
                    <div className="faq-answer">
                      <p>
                        「新年度の始まる４月はもちろん年度途中の入会も可能です。費用はクラスにより異なるため当ホームページで各クラスの内容をご覧ください。初期費用として入会金、教材費、イベント費（小学生未満のみ）がかかります。入会金は入会時のみ、教材費とイベント費は年に１度だけ納めていただきます。」
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      activeFaq === +3 ? "faqs-item active" : "faqs-item"
                    }
                    onClick={e => handleShowFaq(e, +3)}
                  >
                    <div className="faq-link" >
                      <span className="faq-icon" />
                      「どんなイベントがありますか？」
                    </div>
                    <div className="faq-answer">
                      <p>
                        「イースターやハロウィン、クリスマスなど季節のイベントに加えて、バーベキューや外遊びなど、生徒さんだけでなくご家族も一緒に参加していただけるさまざまなイベントを用意しています。」
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      activeFaq === +4 ? "faqs-item active" : "faqs-item"
                    }
                    onClick={e => handleShowFaq(e, +4)}
                  >
                    <div className="faq-link" >
                      <span className="faq-icon" />
                      「振替レッスンはありますか？」
                    </div>
                    <div className="faq-answer">
                      <p>
                        「同じレベルのクラスに空きがあれば振替可能です。事前に予約が必要となります。」
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      activeFaq === +5 ? "faqs-item active" : "faqs-item"
                    }
                    onClick={e => handleShowFaq(e, +5)}
                  >
                    <div className="faq-link" >
                      <span className="faq-icon" />
                      「月謝などの支払い方法は？」
                    </div>
                    <div className="faq-answer">
                      <p>
                        「毎月２６日までに翌月分のお支払い頂いています。入会時に「月謝袋持参」か「銀行振込」のどちらかをご選択いただけます。」
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      activeFaq === +6 ? "faqs-item active" : "faqs-item"
                    }
                    onClick={e => handleShowFaq(e, +6)}
                  >
                    <div className="faq-link" >
                      <span className="faq-icon" />
                      「英語を習うのは初めてなのですが大丈夫でしょうか？」
                    </div>
                    <div className="faq-answer">
                      <p>
                        「レベル別のレッスンなので初心者でも問題ありません。」
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      activeFaq === +7 ? "faqs-item active" : "faqs-item"
                    }
                    onClick={e => handleShowFaq(e, +7)}
                  >
                    <div className="faq-link" >
                      <span className="faq-icon" />
                      「キャンペーンなどはありますか？」
                    </div>
                    <div className="faq-answer">
                      <p>
                        「初めて入会する方も紹介元の生徒さんも割引適用となる「おともだちキャンペーン」を実施中です。さらにご家族が２人以上で一緒に入会すると入会金無料や月謝割引などの特典もご用意しています。」
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      activeFaq === +8 ? "faqs-item active" : "faqs-item"
                    }
                    onClick={e => handleShowFaq(e, +8)}
                  >
                    <div className="faq-link" >
                      <span className="faq-icon" />
                      「保護者の見学はできますか？」
                    </div>
                    <div className="faq-answer">
                      <p>
                        「レッスンの見学はいつでも可能です。マジックミラーを設置しているので、お子さんに気付かれることなく普段のレッスンの様子を見ていただくことができます。」
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      activeFaq === +9 ? "faqs-item active" : "faqs-item"
                    }
                    onClick={e => handleShowFaq(e, +9)}
                  >
                    <div className="faq-link" >
                      <span className="faq-icon" />
                      「子どもがどの程度英語を理解しているのか知りたいのですが？」
                    </div>
                    <div className="faq-answer">
                      <p>
                        「当スクールでは年に１度、お子さんの理解度を測ることのできる「英検Jr.」という英検協会公式のリスニングテストを実施しています。答えに◯をつけるだけなので気軽に受けれて、良い点数がとれれば自信にもつながりおすすめです。」
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </section>
    </>
  )
}

export default Faq
